import type { TJackpotsType } from "@lobby/core/entities";
import { clsx } from "clsx";
import type { ReactNode } from "react";
import { JackpotDecorator } from "../jackpot-decorator";

import "./styles.css";

interface IJackpotTickerProps {
  className?: string;
  type: TJackpotsType;
  children?: ReactNode;
  flip?: boolean;
}

export function JackpotTicker({ className, type, children, flip = false }: IJackpotTickerProps) {
  return (
    <div
      className={clsx(
        "jackpot-ticker",
        type === "mini" && "bg-lochmara text-lochmara",
        type === "grand" && "bg-medium-purple text-medium-purple",
        type === "major" && "bg-fire-bush text-fire-bush",
        type === "ultimate" && "bg-radical-red text-radical-red",
        className,
        flip && "scale-x-[-1]",
      )}
    >
      <div className={clsx("jackpot-ticker_content", flip && "scale-x-[-1]")}>
        <p>{type}</p>
        <div className="flex gap-1">
          <p className="tabular-nums whitespace-nowrap">{children}</p>
        </div>
      </div>
      <div className="jackpot-ticker_decorator-container">
        <JackpotDecorator className="w-7 h-6 lg:w-19 lg:h-14.5" />
      </div>
    </div>
  );
}
