import { CategoryButton } from "@features/category/category-button";
import { FortuneWheelButton } from "@features/fortune-wheel-button";
import { GameSearch } from "@features/game-search";
import { FortuneWheel } from "@lobby/core/entities";
import { Game } from "@lobby/core/entities/game";
import { range, useAuth, useMobile } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useMemo } from "react";
import { PrivateCategoryItems } from "./private-category-items";

export function CategoryList() {
  const { $t } = useTranslate();
  const cid = useSearch({ strict: false, select: ({ cid }) => cid });
  const isMobile = useMobile();
  const navigate = useNavigate();

  const { isAuth } = useAuth();
  const isFortuneWheelAvailable = FortuneWheel.useIsWheelAvailable();

  const { data: categoryList, isPending, isSuccess: isQuerySuccess } = Game.useCategoriesList();
  const categories = useMemo(
    () => categoryList?.filter(({ tag }) => tag === "menu" || !tag),
    [categoryList],
  );

  const isSuccess = isQuerySuccess && categories;

  return (
    <div className="rounded-xs lg:rounded-rounded lg:self-start bg-athens-gray dark:bg-outer-space">
      <div className="flex lg:flex-col p-3 lg:p-5 overflow-x-auto">
        <GameSearch className="mobile-only:hidden" />

        {isFortuneWheelAvailable && !isMobile && <FortuneWheelButton.Desktop />}

        <ul className="flex lg:flex-col gap-2.5 lg:mt-5">
          <li>
            <CategoryButton id="allGames" isActive={!cid}>
              {$t({ defaultMessage: "All Games" })}
            </CategoryButton>
          </li>

          {isAuth && <PrivateCategoryItems />}

          {isPending && range(8)((idx) => <CategoryButton.Skeleton key={idx} />)}
          {isSuccess &&
            categories.map(({ id, name, actionType, actionData }) => {
              switch (actionType) {
                case "game":
                  return (
                    <li key={id}>
                      <CategoryButton
                        id={id}
                        isActive={cid === id}
                        onClick={() => {
                          navigate({
                            to: "/game/$gameId",
                            params: { gameId: actionData as string },
                          });
                        }}
                      >
                        {name}
                      </CategoryButton>
                    </li>
                  );
                default:
                  return (
                    <li key={id}>
                      <CategoryButton id={id} isActive={cid === id}>
                        {name}
                      </CategoryButton>
                    </li>
                  );
              }
            })}
        </ul>
      </div>
    </div>
  );
}
