import { JackpotDecorator } from "@entities/jackpot";
import { type ICongratsModalProps, emitter } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { Modal } from "@shared/ui/modal/modal";
import { clsx } from "clsx";
import { useEffect, useState } from "react";

export function CongratsModal() {
  const { $t } = useTranslate();

  const [isOpened, setIsOpened] = useState(false);
  const [params, setParams] = useState<ICongratsModalProps>({} as ICongratsModalProps);

  const { message, value, colors, onTake } = params ?? {};
  const [primaryColor, secondaryColor] = colors ?? [];

  function handleTake() {
    onTake();
    setIsOpened(false);
  }

  useEffect(() => {
    const unbind = emitter.on("CONGRATS_MODAL_OPEN", (params) => {
      setParams(params);
      setIsOpened(true);
    });

    return unbind;
  }, []);

  return (
    <Modal open={isOpened}>
      <Modal.Overlay />
      <Modal.Panel className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2">
        <div
          className={clsx(
            "relative w-max max-w-[90vw] rounded-rounded rounded-tl-[3rem] rounded-br-[3rem] border-1 p-1.5 lg:rounded-tl-[5.25rem] lg:rounded-br-[5.25rem] lg:p-2.5",
          )}
          style={{
            backgroundColor: primaryColor + "4d",
            borderColor: primaryColor,
          }}
        >
          <div className="-top-3 -right-10 absolute lg:top-[-1.25rem] lg:right-[-3.75rem]">
            <JackpotDecorator className="w-28 lg:w-[12.5rem]" gradient={colors} />
          </div>
          <div
            className={clsx(
              "rounded-inherit rounded-tl-[2.625rem] rounded-br-[2.625rem] lg:rounded-tl-[4.9375rem] lg:rounded-br-[4.9375rem]",
            )}
            style={{
              backgroundImage: `linear-gradient(to bottom, ${primaryColor}, ${secondaryColor})`,
            }}
          >
            <div className="flex flex-col gap-2.5 p-5 text-center lg:gap-5 lg:p-10">
              <div className="font-commissioner font-extrabold text-white text-xl uppercase italic tracking-wider [text-shadow:0.25rem_0.25rem_0_rgba(0,0,0,0.3)] lg:text-4xl">
                {$t({ defaultMessage: "congrats" }).toUpperCase()}
                {skipIntl("!")}
              </div>
              <p className="whitespace-pre-line font-bold text-lg text-white uppercase lg:text-2xl">
                {message}
              </p>
              <div className="font-commissioner font-extrabold text-2xl text-white uppercase italic tracking-wider [text-shadow:0.25rem_0.25rem_0_rgba(0,0,0,0.3)] lg:text-4xl">
                {value}
              </div>
              <div>
                <button
                  className="min-w-18 whitespace-nowrap rounded-rounded bg-java px-4 py-2 font-bold text-ebony-clay text-xs uppercase [box-shadow:0.375rem_0.375rem_0_rgba(0,0,0,0.25)] hover:text-white active:translate-x-[0.375rem] active:translate-y-[0.375rem] lg:h-9 lg:px-7.5 lg:text-sm lg:active:translate-x-[0.5rem] lg:active:translate-y-[0.5rem] active:[box-shadow:none] lg:[box-shadow:0.5rem_0.5rem_0_rgba(0,0,0,0.25)]"
                  type="button"
                  onClick={handleTake}
                >
                  {$t({ defaultMessage: "take" })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
