import { CategoryIcon } from "@features/category/category-icon";

import { useNavigateToCategory } from "../lib/use-navigate-to-category";

interface ICategoryButtonProps {
  children: React.ReactNode;
  isActive: boolean;
  disabled?: boolean;
  id: string | number;
  onClick?: VoidFunction;
}

export function CategoryButton({
  children,
  isActive,
  disabled,
  id,
  onClick,
}: ICategoryButtonProps) {
  const navigate = useNavigateToCategory();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(id);
    }
  };

  return (
    <button
      className={`active:text-white dark:bg-bright-gray disabled:bg-mercury disabled:text-dusty-gray dark:disabled:bg-ebony-clay dark:disabled:text-bright-gray rounded-sm lg:rounded-rounded lg:px-7.5 font-bold uppercase flex items-center justify-center lg:justify-start flex-col gap-1 lg:gap-2.5 lg:flex-row select-none disabled:pointer-events-none disabled:cursor-not-allowed text-2xs lg:text-base h-12.5 lg:h-15 px-5 w-full dark:active:bg-java ${isActive ? "bg-keppel text-white dark:bg-java" : "bg-white active:bg-keppel"}`}
      type="button"
      disabled={disabled}
      onClick={handleClick}
    >
      <CategoryIcon className="lg:text-lg text-sm" id={id} />
      <span className="truncate">{children}</span>
    </button>
  );
}
