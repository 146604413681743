import { SVGIcon } from "@shared/ui";
import { clsx } from "clsx";

interface ITakeBonusButtonProps {
  className?: string;
  type: string;
  amount: string;
  onClick: VoidFunction;
}

export function TakeBonusButtonView({ className, type, amount, onClick }: ITakeBonusButtonProps) {
  return (
    <div className={clsx(className, "mobile-only:*:w-full")}>
      <button
        className="lg:rounded-rounded lg:p-[0.3125rem] lg:h-24 lg:shadow-8 bg-gradient-to-l from-java to-[#2c7e7b] rounded-sm border-keppel p-0.5 filter shadow-3"
        type="button"
        onClick={onClick}
      >
        <div className="lg:pl-[5.625rem] lg:pr-[1.625rem] lg:py-5 bg-gradient-to-l from-burnt-sienna to-[#f00] rounded-inherit flex items-center py-1.5 pr-6 pl-24 h-full">
          <SVGIcon
            className="lg:left-0 lg:w-[9.625rem] lg:-translate-x-[3.875rem] lg:translate-y-[-0.8125rem] absolute left-7 aspect-square w-17 -translate-y-[0.1875rem]"
            name="gift"
          />
          <div className="font-black text-white italic uppercase text-start font-commissioner tracking-wider mobile-only:flex-center mobile-only:gap-x-2 mobile-only:flex-wrap lg:[text-shadow:0.25rem_0.25rem_0_rgba(0,0,0,0.7)] [text-shadow:0.125rem_0.125rem_0_rgba(0,0,0,0.7)]">
            <p className="lg:text-xl text-sm mobile-only:leading-none">{type}</p>
            <p className="lg:text-2xl text-lg mobile-only:leading-none">{amount}</p>
          </div>
        </div>
      </button>
    </div>
  );
}
