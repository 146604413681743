import { LanguageSelector } from "@features/language-selector";
import { LogoutButton } from "@features/player/logout-button";
import { ThemeSwitch } from "@features/theme-switch";
import { useTranslate } from "@lobby/ocb-intl";
import { Logo, Modal } from "@shared/ui";
import { memo, useRef } from "react";
import {
  ProfileBalanceDetails,
  ProfilePlayerBalanceView,
  ProfileSectionControls,
  useProfileSections,
} from "../common";
import type { TProfileSectionItem } from "../common";
import { ProfileSectionMobile } from "./profile-section.mobile";

interface IProfileComponentMobileProps {
  currentSection: TProfileSectionItem | undefined;
  showLoadingOverlay: boolean;
  close: VoidFunction;
}

export const ProfileComponentMobile = memo(function ProfileComponentMobile({
  currentSection,
  showLoadingOverlay,
  close,
}: IProfileComponentMobileProps) {
  const { $t } = useTranslate();
  const transitionContainerRef = useRef<HTMLDivElement>(null);
  const currentSectionRef = useRef(currentSection);
  const sections = useProfileSections();

  const isHomeSection = !currentSection;
  currentSectionRef.current = isHomeSection ? currentSectionRef.current : currentSection;

  return (
    <Modal.Panel className="size-full border-keppel bg-mercury dark:border-java dark:bg-ebony-clay">
      <Modal.Title className="mb-0 p-3 lg:justify-between">
        <Modal.CloseButton className="[&&]:relative" onClick={close} />
        <Logo className="ml-2.5 h-7 w-36" />
        <LogoutButton className="ml-auto" />
      </Modal.Title>

      <div
        className="flex h-full min-h-0 transition-transform duration-200 *:w-full *:min-w-full *:p-3 *:pt-0"
        ref={transitionContainerRef}
        style={{
          transform: `translateX(${isHomeSection ? "0%" : "-100%"})`,
        }}
      >
        <div
          className="scrollbar-thin overflow-y-auto overflow-x-hidden transition-opacity duration-200"
          style={{
            opacity: isHomeSection ? 1 : 0,
          }}
        >
          <ProfilePlayerBalanceView />
          <ProfileBalanceDetails className="mt-2" />
          <div className="mt-2">
            <ProfileSectionControls
              currentSectionId={currentSectionRef.current?.id}
              items={sections}
            />
          </div>
          <div className="flex-c-sb px-2.5 py-5.5">
            <div className="flex-center">
              <span className="font-bold text-blue-bayoux text-xs uppercase">
                {$t({ defaultMessage: "language" })}
              </span>
              <LanguageSelector className="h-fit" />
            </div>
            <div className="flex-center gap-3">
              <span className="font-bold text-blue-bayoux text-xs uppercase">
                {$t({ defaultMessage: "color theme" })}
              </span>
              <ThemeSwitch />
            </div>
          </div>
        </div>

        <ProfileSectionMobile
          currentSection={currentSectionRef.current}
          isActive={!isHomeSection}
        />
      </div>

      <Modal.PendingOverlay isActive={showLoadingOverlay} />
    </Modal.Panel>
  );
});
